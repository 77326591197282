<template>
  <transition-group name="fade">
    <component
      :is="component.component"
      v-for="component of parts"
      :id="component.data?.slug||component.slug"
      :key="component.data?.id||component.id"
      :payload="component.data||component"
      class="fade-item"
    >
      <h2>Unable to load component «{{ component.component }}».</h2>
    </component>
  </transition-group>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import LandingHeader from '@/components/parts/LandingHeader.vue';
import Header from '@/components/parts/Header.vue';

export default {
  components: {
    Artikel: defineAsyncComponent(() => import('@/components/parts/Artikel.vue')),
    Auflistung: defineAsyncComponent(() => import('@/components/parts/Auflistung.vue')),
    Ausstattungen: defineAsyncComponent(() => import('@/components/parts/Ausstattungen.vue')),
    BannerSlider: defineAsyncComponent(() => import('@/components/parts/BannerSlider.vue')),
    BildUmbruch: defineAsyncComponent(() => import('@/components/parts/BildUmbruch.vue')),
    BlogOverview: defineAsyncComponent(() => import('@/components/parts/BlogOverview.vue')),
    BlogTeaser: defineAsyncComponent(() => import('@/components/parts/BlogTeaser.vue')),
    Dienstleistung: defineAsyncComponent(() => import('@/components/structure/Dienstleistung.vue')),
    DienstleistungKacheln: defineAsyncComponent(() => import('@/components/parts/DienstleistungKacheln.vue')),
    DreispaltigerText: defineAsyncComponent(() => import('@/components/parts/DreispaltigerText.vue')),
    Farben: defineAsyncComponent(() => import('@/components/parts/Farben.vue')),
    Footer: defineAsyncComponent(() => import('@/components/structure/Footer.vue')),
    Formular: defineAsyncComponent(() => import('@/components/parts/Formular.vue')),
    Galerie: defineAsyncComponent(() => import('@/components/parts/Galerie.vue')),
    Grssen: defineAsyncComponent(() => import('@/components/parts/Groessen.vue')),
    Header,
    LandingHeader,
    Map: defineAsyncComponent(() => import('@/components/parts/Map.vue')),
    Materialien: defineAsyncComponent(() => import('@/components/parts/Materialien.vue')),
    Newsletter: defineAsyncComponent(() => import('@/components/parts/Newsletter.vue')),
    NotFound: defineAsyncComponent(() => import('@/components/structure/NotFound.vue')),
    PopUpKacheln: defineAsyncComponent(() => import('@/components/parts/PopUpKacheln.vue')),
    Produkt: defineAsyncComponent(() => import('@/components/structure/Produkt.vue')),
    Referenz: defineAsyncComponent(() => import('@/components/structure/Referenz.vue')),
    ReferenzenKacheln: defineAsyncComponent(() => import('@/components/parts/ReferenzenKacheln.vue')),
    ExternesScript: defineAsyncComponent(() => import('@/components/parts/ExternesScript.vue')),
    Seite: defineAsyncComponent(() => import('@/components/structure/Seite.vue')),
    Suche: defineAsyncComponent(() => import('@/components/structure/Suche.vue')),
    Text: defineAsyncComponent(() => import('@/components/parts/Text.vue')),
    TextmitBild: defineAsyncComponent(() => import('@/components/parts/TextMitBild.vue')),
    Video: defineAsyncComponent(() => import('@/components/parts/Video.vue')),
    ZweispaltigeKacheln: defineAsyncComponent(() => import('@/components/parts/ZweispaltigeKacheln.vue')),
  },
  props: { inject: { type: Object, default: Object } },
  computed: {
    parts() {
      return this.inject.filter((part) => !part.data?.hidden);
    },
  },
};
</script>
