<template>
  <div v-click-outside="() => (open = false)" class="KontaktSlideIn" :class="{ open }">
    <a class="label" href="#" @click.prevent="open = !open">
      <div class="wrapper">
        <span class="text"> Kontakt </span>
        <Arrow class="icon" />
      </div>
    </a>

    <div class="content pl-4 pr-8 py-9">
      <Go :to="'tel:' + kontakt?.telefon" class="block mb-3">
        <Phone class="icon" /> T {{ kontakt?.telefon }}
      </Go>
      <Go :to="kontakt?.link" class="block">
        <Mail class="icon" /> {{ kontakt?.linkText }}
      </Go>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import Arrow from '@/assets/images/arrow.svg';
import Phone from '@/assets/images/Tel.svg';
import Mail from '@/assets/images/Brief.svg';

export default {
  components: { Arrow, Phone, Mail },
  // do not forget this section
  directives: {
    ClickOutside,
  },
  data: () => ({
    open: false,
  }),
  computed: {
    kontakt() {
      return this.$store.state.router.layout.kontakt;
    },
  },
};
</script>

<style scoped lang="scss">
.KontaktSlideIn {
  position: fixed;
  right: 0;
  top: 11rem;
  z-index: 12;
  background-color: var(--color-blue);
  transform: translateX(100%);
  transition: 0.25s ease-in-out;
  &.open {
    transform: translateX(0);
    .label {
      .icon {
        transform: rotate(90deg);
      }
    }
  }
  @screen md {
    &:hover {
      transform: translateX(0);
      .label {
        .icon {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.label {
  border-top-left-radius: px(12);
  border-bottom-left-radius: px(12);
  --width: 2.4rem;
  position: absolute;
  left: calc(var(--width) * -1);
  height: 100%;
  width: var(--width);
  cursor: pointer;
  user-select: none;
  background-color: var(--color-blue);

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: px(15);
  color: #FFF;

  transition: 0.25s ease;

  @screen md {
    --width: 3.35rem;
    font-size: px(24);
  }
  .wrapper {
    display: flex;
    align-items: center;
    white-space: nowrap;
    transform: rotate(-90deg);
  }

  .icon {
    margin-left: px(20);
    height: px(13);
    transform: rotate(-90deg);
    transition: 0.3s ease;

    @screen md {
      height: px(16);
    }
  }
}

.content {
  .icon {
    display: inline-block;
    width: px(20);
    margin-right: 1rem;
    fill: #FFF;

    @screen md {
      width: px(27);
    }
  }
  a {
    color: #FFF;
    font-size: px(15);
    @screen md {
      font-size: px(24);
    }
  }
}
</style>
