<template>
  <footer class="Footer">
    <div class="flex flex-col sm:flex-row justify-between mb-6 sm:mb-8 md:mb-12 lg:mb-16">
      <div class="logo">
        <Go :to="'/de'">
          <Logo />
        </Go>
      </div>
      <div class="socials my-6 sm:my-0 items-center">
        <Go v-if="payload?.instagram" :to="payload?.instagram" target="_blank">
          <Insta />
        </Go>
        <Go v-if="payload?.facebook" :to="payload?.facebook" target="_blank">
          <Facebook />
        </Go>
        <Go v-if="payload?.twitter" :to="payload?.twitter" target="_blank">
          <XTwitter />
        </Go>
        <Go v-if="payload?.linkedin" :to="payload?.linkedin" target="_blank">
          <LinkedIn />
        </Go>
      </div>
    </div>
    <div class="row row-12">
      <div class="col-12 md:col-6 lg:col-4">
        <div class="adress" v-html="payload?.adresse" />
        <div class="lg:mb-0 md:mb-14">
          <label for="emailFooter">Anmeldung Newsletter</label>
          <MailChimp class="w-full block" field="emailFooter" />
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-4 flex mt-12 md:mt-0 lg:justify-start md:justify-start lg:justify-start">
        <div class="footerText md:mb-0" v-html="payload?.textColumn1"/>
      </div>
      <div class="col-12 md:col-6 lg:col-4 flex mt-12 md:mt-0 lg:justify-start md:justify-start lg:justify-start">
        <div class="footerText" v-html="payload?.textColumn2"/>
      </div>
      <!---
      <div class="col-12 md:col-6 lg:col-4 flex mt-12 md:mt-0 lg:justify-start md:justify-start lg:justify-start">
        <div class="footerText" v-html="payload?.textColumn3"/>
      </div>
      --->
      <div class="col-12 lg:col-12 mt-12 lg:mt-0 flex justify-end md:justify-end md:items-end">
        <div class="footerNavi">
          <Go v-for="(item) in payload?.footerLinks" :key="item.uri" :to="item?.uri">
            {{ item?.title }}
          </Go>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from '@/assets/images/Logo Title.svg';
import Insta from '@/assets/images/Insta.svg';
import Facebook from '@/assets/images/Facebook.svg';
import XTwitter from '@/assets/images/XTwitter.svg';
import LinkedIn from '@/assets/images/Linkedin.svg';
import Go from '@/components/utils/Go.vue';
import MailChimp from '../utils/MailChimp.vue';

export default {
  components: {
    Go,
    Logo,
    XTwitter,
    LinkedIn,
    Facebook,
    Insta,
    MailChimp,
  },
  computed: {
    payload() {
      return this.$store.state.router.layout.footer;
    },
  },
};
</script>

<style scoped lang="scss">
  footer {
    margin-top: px(60);
    background-color: var(--color-black);
    padding: px(40) px(22) px(30) px(22);
    @screen sm {
      margin-top: px(80);
      padding: px(50) px(35) px(30) px(35);
    }
    @screen md {
      margin-top: px(100);
      padding: px(60) px(52) px(30) px(52);
    }
    @screen lg {
      margin-top: px(120);
    }

   :deep(a) {
      color: white;
    }

    label {
      display: block;
      font-size: px(24);
      line-height: px(32);
      display: block;
      margin-bottom: px(10);
    }

    :deep(.MailChimp) {
      input {
        border: 3px solid var(--color-blue);
        background: transparent;
        color: #FFF;
        padding: px(5) px(60) px(5) px(20);
        border-radius: px(70);
        font-size: px(24);
        & ::placeholder {
          color: var(--color-brightgrey);
        }
      }
      button {
        opacity: 1;
        &:hover {
          svg {
            opacity: 0.75;
          }
        }
        svg {
          width: px(50);
          height: px(50);
        }
      }
    }

    svg {
      transition: 0.25s all ease-in-out;
      fill: #FFF;
      &:hover {
        fill: var(--color-blue);
      }
    }
    .logo {
      a {
        display: table;
        margin-left: -0.9rem;
        @screen sm {
          margin-left: -1.61rem;
        }
        @screen md {
          margin-left: -1.61rem;
        }
      }
      svg {
        width: px(323);
        height: px(63);
        @screen sm {
          width: px(423);
          height: px(73);
        }
      }
    }
   .adress {
     :deep(p) {
       margin-bottom: px(24);
       margin-right: px(20);
     }
     a {
       color: #FFF;
       text-decoration: none;
     }
   }
    .socials {
      display: flex;
      svg {
        width: px(40);
        height: auto;
      }
      a + a {
        margin-left: px(20);
      }
    }
    .footerNavi {
      display: flex;
      flex-direction: column;
      @screen sm {
        flex-direction: row;
      }
      a {
        font-size: px(24);
        line-height: px(32);
        font-weight: 600;
        + a {
          margin-top: px(8);
          margin-left: px(0);
          @screen sm {
            margin-top: px(0);
            margin-left: px(50);
          }
          @screen md {
            margin-left: px(70);
          }
        }
      }
    }
    .footerText {
        :deep(p) {
          @screen sm {
            margin-bottom: px(5);
          }
          @screen md {
            margin-bottom: 3.56rem;
            margin-right: px(20);
          }

        }
    }
  }
</style>
