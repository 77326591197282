<template>
  <div
    class="LandingHeader relative"
    :style="{'--background': payload.video === null && payload.bild.large ? 'url('+payload.bild.large+')' : ''}"
  >
    <div class="overlay flex items-end justify-start">
      <div class="links flex flex-col md:flex-row justify-start md:justify-end items-start md:items-end">
        <Go
          v-for="link of payload.verlinkungen"
          :key="link.link"
          :to="link.link"
          class="link"
          v-text="link.title"
        />
      </div>
    </div>
    <video
      v-if="payload.video !== null && payload.video.length"
      ref="video"
      autoplay
      loop
      disablePictureInPicture
      controlsList="nodownload noremoteplayback"
      playsInline
      class="video"
      muted="muted"
      :poster="payload.bild.large"
    >
      <source
        v-for="video of payload.video"
        :key="video.type"
        :src="video.link"
        :type="video.type"
      >
    </video>
  </div>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
};
</script>

<style scoped lang="scss">
.LandingHeader {
  color: #FFF;
  height: calc(100vh - 3.484rem);
  background-image: var(--background);

  @screen sm {
    height: calc(100vh - 4.049rem);
  }
  @screen md {
    height: calc(100vh - 6.431rem);
  }
  @screen lg {
    height: calc(100vh - 7.425rem);
  }
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  background-position: center, center;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: -webkit-linear-gradient(180deg, transparent 0%, #16161500 20%, #161615 100%);
    background-image: linear-gradient(180deg, transparent 0%, #16161500 20%, #161615 100%);
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
    background-position: center, center;
    z-index: 9;
    @screen md {
      background-image: linear-gradient(-90deg, transparent 0%, #16161500 50%, #161615 100%);
      background-image: -webkit-linear-gradient(-90deg, transparent 0%, #16161500 50%, #161615 100%);
    }
  }
  .links {
    width: 100%;
    padding: px(45) px(25);
    @screen sm {
      padding: px(45) px(40);
    }
    @screen md {
      width: auto;
      padding: px(45) px(60);
    }
    @screen lg {
      padding: px(45) px(100);
    }
    .link {
      transition: 0.25s all ease-in-out;
      font-size: px(22);
      line-height: 1.2;
      width: 100%;
      text-align: left;
      @screen sm {
        font-size: px(24);
      }
      @screen md {
        width: auto;
        font-size: px(28);
      }
      @screen lg {
        font-size: px(32);
      }
      & + .link {
        margin-left: px(0);
        margin-top: px(20);
        @screen md {
          margin-top: px(0);
          margin-left: px(85);
        }
        @screen lg {
          margin-left: px(130);
        }
      }
      &:hover {
        color: var(--color-blue);
      }
    }
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
