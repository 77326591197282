import {
  onMounted, onUnmounted, computed, watch,
} from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head';
import { useGtm } from '@gtm-support/vue-gtm';

const getUri = () => `${window.location.pathname}${window.location.search}${window.location.hash}`;

export default function () {
  const store = useStore();
  const gtm = useGtm();

  function popstate(history) {
    store.dispatch('router/pop', { path: getUri(), data: history.state });
  }

  const meta = computed(() => store.state.router.popup?.meta || store.state.router.page?.meta);
  useHead({
    htmlAttrs: { lang: computed(() => store.state.router.locale) },
    title: computed(() => meta.value?.title),
    meta: computed(() => [
      { name: 'description', content: meta.value?.description },
      { property: 'og:description', content: meta.value?.description },
      { property: 'og:title', content: meta.value?.title },
      { property: 'og:type', content: 'website' },
    ]),
    link: computed(() => {
      if (!meta.value) return [];
      const tags = [];
      tags.push({
        rel: 'alternate',
        hreflang: store.state.router.locale,
        href: meta.value?.currentUrl,
      });
      tags.push({
        rel: 'canonical',
        href: meta.value?.currentUrl,
      });
      return tags;
    }),
  });

  watch(() => store.state.router.page, () => {
    if (gtm) gtm.trackView(meta.value?.title, window.location.pathname);
  });

  onMounted(async () => {
    try {
      await store.dispatch('router/init', { uri: getUri(), hydrate: !!window.INITAL_ROUTER });
    } catch (err) {
      console.error('Server unreachable', err);
      window.document.dispatchEvent(new Event('prerender-trigger'));
    }

    window.addEventListener('popstate', popstate);
  });

  onUnmounted(() => {
    window.removeEventListener('popstate', popstate);
  });
}
