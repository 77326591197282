<template>
  <div class="Header">

    <video
      v-if="payload.video !== null && payload.video.length"
      ref="video"
      autoplay
      loop
      disablePictureInPicture
      controlsList="nodownload noremoteplayback"
      playsInline
      class="bg"
      muted="muted"
    >
      <source
        v-for="video of payload.video"
        :key="video.type"
        :src="video.link"
        :type="video.type"
      >
    </video>
    <div v-else class="bg" :style="{'--background': payload.bild.large ? 'url('+payload.bild.large+')' : ''}" />

    <div
      v-if="payload.titel"
      class="title flex flex-col md:flex-row justify-start md:justify-end items-start md:items-end"
    >
      <div class="h2 text-left" v-html="payload.titel" />
    </div>

  </div>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
};
</script>

<style scoped lang="scss">
.Header {
  position: relative;
  color: #FFF;
  height: calc(100vh - 3.484rem);
  background-image: linear-gradient(180deg, transparent 0%, #16161500 50%, #161615 100%);

  @screen sm {
    height: calc(100vh - 4.049rem);
  }
  @screen md {
    height: calc(100vh - 6.431rem);
  }
  @screen lg {
    height: calc(100vh - 6.225rem);
  }
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  background-position: center, center;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  .title {
    width: 100%;
    padding: px(45) px(25);
    @screen sm {
      padding: px(45) px(40);
    }
    @screen md {
      width: auto;
      padding: px(45) px(60);
    }
    @screen lg {
      padding: px(45) px(100);
    }
    h1 {
      transition: 0.25s all ease-in-out;
      font-size: px(22);
      line-height: 1.2;
      width: 100%;
      text-align: center;
      @screen sm {
        font-size: px(24);
      }
      @screen md {
        text-align: left;
        width: auto;
        font-size: px(28);
      }
      @screen lg {
        font-size: px(32);
      }
    }
  }
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  background: black;
  background-image: var(--background);
  background-size: cover;
  background-position: center;
}
</style>
