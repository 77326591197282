<template>
  <span v-if="!to">
    <slot />
  </span>

  <a
    v-else-if="isAnchor(to)"
    :href="isAnchor(to)"
    class="cursor-pointer"
    @click="$emit('click', $event)"
  >
    <slot />
  </a>

  <a
    v-else-if="to.startsWith('http')"
    :target="newTab ? '_blank' : '_self'"
    :href="to"
    rel="nofollow noopener"
    @click="$emit('click', $event)"
  >
    <slot />
  </a>

  <a
    v-else-if="to.startsWith('tel:') || to.startsWith('mailto:')"
    href="#"
    @click.prevent="special(to)"
  >
    <slot />
  </a>

  <a v-else :href="to" @click="internal($event)">
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    to: { type: String, default: null },
    newTab: { type: Boolean, default: false },
  },
  emits: ['click'],
  methods: {
    isAnchor(to) {
      if (to.startsWith('#')) return to;
      return false;
    },
    special(to) {
      this.$emit('click');
      window.location.href = to.replace(/ /g, '');
    },
    internal(e) {
      this.$emit('click', e);
      if (e.which === 2 || e.button === 4 || e.ctrlKey || e.metaKey) return;
      if (e.defaultPrevented) return;
      e.preventDefault();
      this.$store.dispatch('router/push', { uri: this.to });
    },
  },
};
</script>
