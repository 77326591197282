import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import smoothscroll from 'smoothscroll-polyfill';
import { createGtm } from '@gtm-support/vue-gtm';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';
import Image from '@/components/utils/Image.vue';

import store from './store';
import checkView from './utils/vue-check-view';
import { cleanSvgDirective } from './utils/svg';

import './assets/styles/main.scss';

// polyfills
// --------------------------------
smoothscroll.polyfill();

// app
// --------------------------------
const app = createApp(App);

const head = createHead();
app.use(head);

app.use(store);
app.use(checkView, { insideThreshold: () => (window.innerWidth < 960 ? 0.1 : 0.15) });
app.use(createGtm({ id: 'GTM-5D6CXJ9', enabled: !window.prerender }));

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Image', Image);

app.directive('clean-svgs', cleanSvgDirective);

app.mount('#app');
if (!window.prerender) setTimeout(() => window.document.body.classList.remove('no-anim'), 100);
