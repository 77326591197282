<template>
  <div id="mc_embed_signup" class="MailChimp">
    <form
      id="mc-embedded-subscribe-form"
      action="https://solutionpool.us3.list-manage.com/subscribe/post?u=a5855109523c693373bab9d86&amp;id=3223789701&amp;f_id=002f54e2f0"
      method="post"
      name="mc-embedded-subscribe-form"
      class="validate"
      target="_blank"
      novalidate
    >
      <div id="mc_embed_signup_scroll" class="relative inline-block">

        <div class="mc-field-group">
          <label>
            <span class="hidden">Email Address<span class="asterisk">*</span></span>
            <input
              :id="field"
              type="email"
              value=""
              name="EMAIL"
              class="required email"
              placeholder="Ihre E-Mail"
              required
            >
          </label>
          <span id="mce-EMAIL-HELPERTEXT" class="helper_text" />
        </div>
        <div id="mce-responses" class="clear">
          <div id="mce-error-response" class="response" style="display:none" />
          <div id="mce-success-response" class="response" style="display:none" />
        </div>
        <div style="position: absolute; left: -5000px;" aria-hidden="true">
          <input
            type="text"
            name="b_a5855109523c693373bab9d86_bc7e687fcc"
            tabindex="-1"
            value=""
          >
        </div>
        <div class="clear">
          <button
            id="mc-embedded-subscribe"
            type="submit"
            value="Subscribe"
            name="subscribe"
            class="button"
          >
            <RoundArrow />
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import RoundArrow from '@/assets/images/PfeilMenu.svg';

export default {
  components: {
    RoundArrow,
  },
  props: {
    field: { type: String },
  },
};
// src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
// (function ($) { window.fnames = new Array(); window.ftypes = new Array(); fnames[0] = 'EMAIL'; ftypes[0] = 'email'; fnames[1] = 'FNAME'; ftypes[1] =
// 'text'; fnames[2] = 'LNAME'; ftypes[2] = 'text'; fnames[3] = 'ADDRESS'; ftypes[3] = 'address'; fnames[4] = 'PHONE'; ftypes[4] = 'phone'; }(jQuery));
// const $mcj = jQuery.noConflict(true);
</script>

<style scoped lang="scss">
  input {
    max-width: 100%;
    width: 100%;
    color: #FFF;
    border: 2px solid var(--color-blue);
    background-color: transparent;
    padding: px(10) px(40) px(10) px(20);
    border-radius: px(36);
    font-size: px(20);
    @screen sm {
      padding: px(10) px(50) px(10) px(20);
      font-size: px(22);
    }
    @screen md {
     // width: px(680);
      padding: px(10) px(60) px(10) px(20);
      font-size: px(26);
    }
    @screen lg {
      padding: px(10) px(80) px(10) px(40);
      font-size: px(32);
    }
    &::placeholder {
      color: #FFF;
    }
  }
  svg {
    transition: 0.25s all ease-in-out;
    width: 3.3rem;
    height: 3.4rem;
    @screen sm {
      width: 3.45rem;
      height: 3.55rem;
    }
    @screen md {
      width: 3.96rem;
      height: 3.96rem;
    }
    @screen lg {
      width: 4.46rem;
      height: 4.46rem;
    }
  }
  button {
    position: absolute;
    top: 0;
    right: 0;
    &:hover {
      svg {
        opacity: 0.75;
      }
    }
  }
</style>
